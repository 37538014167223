import React from "react";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import data from "./CardWithMapData";
import Container from "@mui/material/Container";
import "./Services.css";

function CardWithMap() {
  const renderData = data.map(
    ({ icon, backgroundColor, title, description, route }) => (
      <Grid className="mainHoverTransition" item xs={12} md={4} lg={4} xl={4}>
        <div
          //   onMouseEnter={() => setOnHover(true)}
          //   onMouseLeave={() => setOnHover(false)}
          className="hoverTransition"
        >
          <Grid
            item
            style={{
              borderRadius: "50%",
              width: "70px",
              height: "70px",
              margin: "auto",
            }}
            backgroundColor={backgroundColor}
          >
            <div>{icon}</div>
          </Grid>
          <Grid item>
            <MKTypography
              variant="h4"
              className="hoverTransitionText"
              // style={{
              //   // color: onHover ? "white" : "",
              // }}
            >
              {title}
            </MKTypography>
          </Grid>
          <Grid item>
            <MKTypography
              className="hoverTransitionTextDetails"
              // style={
              //   {
              //     color: onHover ? "white" : "",
              //   }
              // }
            >
              {description}
            </MKTypography>
          </Grid>
        </div>
      </Grid>
    )
  );
  return <Grid container>{renderData}</Grid>;
}

export default CardWithMap;
