// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import devStrings from "assets/images/devStrings.png";

const date = new Date().getFullYear();

export default {
  menus: [
    {
      name: "Contact",
      items: [
        {
          name: "Email",
          icon: <EmailIcon />,
          description: "contact@devstrings.com",
          href: "/contact",
        },
        {
          icon: <LocationOnIcon />,
          name: "Address",
          description:
            "2nd Floor Awais plaza, 13th street Zeeshaan Park, West Canal Road, behind KIA motors, Faisalabad, Punjab, Pakistan",
          href: "/about",
          // target:"_blank"
        },
      ],
    },
    {
      name: "Important Links",
      items: [
        { name: "Home", href: "/home" },
        { name: "About Us", href: "/about" },
        { name: "Services", href: "/services" },
        { name: "Contact Us", href: "/contact" },
      ],
    },
    {
      name: "About Us",
      items: [
        {
          name: "DevStrings is a company for Software Development, Web Development, Graphics designing, E-commerce and Digital Marketing which delivers exclusive and finest solutions to the clients being top internet marketing firm.",
          href: "/about",
        },
      ],
    },
  ],
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/devstrings",
    },
    {
      icon: <TwitterIcon />,
      link: "https://www.twitter.com/StringsDev",
    },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/devstrings",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/company/devstrings",
    },
  ],
  brand: {
    // name: "DevStrings",
    image: devStrings,
    route: "/",
  },

  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; 2020 DevStrings. All Rights Reserved.{" "}
    </MKTypography>
  ),
};
