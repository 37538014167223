// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import AboutUs from "layouts/pages/landing-pages/about-us";
import ContactUs from "layouts/pages/landing-pages/contact-us";
import Presentation from "layouts/pages/presentation";
import Services from "pages/LandingPages/Services/Services";
import Projects from "pages/LandingPages/Projects";

const routes = [
  {
    name: "Home",
    icon: <Icon>home</Icon>,
    component: <Presentation />,
    route: "/home",
    // columns: 1,
    // rowsPerColumn: 2,
    // collapse: [
    //   {
    //     name: "landing pages",
    //     collapse: [
    //       {
    //         name: "author",
    //         route: "/pages/landing-pages/author",
    //         component: <Author />,
    //       },
    //     ],
    //   },
    //   {
    //     name: "Sign In",
    //     collapse: [
    //       {
    //         name: "sign in",
    //         route: "/sign-in",
    //         component: <SignIn />,
    //       },
    //     ],
    //   },
    // ],
  },
  {
    name: "Services",
    icon: <Icon>settings</Icon>,
    route: "/services",
    component: <Services />,
  },
  {
    name: "About Us",
    icon: <Icon>info</Icon>,
    route: "/about",
    component: <AboutUs />,
  },
  {
    name: "Contact Us",
    icon: <Icon>call</Icon>,
    route: "/contact",
    component: <ContactUs />,
  },
  {
    route: "/projects",
    component: <Projects />,
  },
];

export default routes;
