// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/ItDevices.jpeg";
import bgBack from "assets/images/tech.jpg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={<>IT Agency Services</>}
                description="Technology partner for startups, businesses, entrepreneurs an innovator. Hire dedicated teams of software developers & eCommerce services providers."
              />
              <RotatingCardBack
                image={bgBack}
                title="Projects"
                description="We’re a team of passionate, dedicated people who have one overreaching goal – Your Success is Our Success."
                action={{
                  type: "internal",
                  route: "/services",
                  label: "Services",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="phone_iphone"
                  title="App Development"
                  description="Hire app developers to develop your apps for mobile devices.."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="language"
                  title="Web Development"
                  description="Hire web designers and developers to develop your websites in a distinct way."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Digital Marketing"
                  description="Hire digital marketers to promote your products, business and services."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="group"
                  title="IT Consultancy"
                  description="Hire professionals to get your challenges solved in Infotmation Technology."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="shopping_cart"
                  title="E-Commerce"
                  description="Hire experts to build , run and establish your E-commerce business."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="IT Solutions"
                  description="Contact us for build in IT solutions provided by our team of professionals."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
