// @mui material components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Material Kit 2 React components
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';

// Material Kit 2 React examples
import HorizontalTeamCard from 'examples/Cards/TeamCards/HorizontalTeamCard';

// Images
import Hamza from 'assets/images/hamza.jpg';
import Usman from 'assets/images/usman.jpg';
import Nadeem from 'assets/images/nadeem.jpg';

import Abdullah from 'assets/images/abdullah.jpg';
import Asim from 'assets/images/asim.jpg';
import Rizwan from 'assets/images/rizwan.jpg';
import usmanSharif from 'assets/images/usmanSharif.jpg';
import Mehran from 'assets/images/mehran.jpg';

function Team() {
  return (
    <MKBox
      component='section'
      variant='gradient'
      bgColor='white'
      position='relative'
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      style={{ borderRadius: '10px', paddingTop: '60px' }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant='h3' color='dark'>
              Our Expert Team
            </MKTypography>
            <MKTypography variant='body2' color='dark' opacity={0.8}>
              Every member of our team owns the company as their own. Together
              we ensure incredible delivery in the field of outsourcing software
              development and E-Commerece.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                image={Usman}
                name='Usman Hafeez'
                position={{ color: 'white', label: 'Senior Software Engineer' }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                image={usmanSharif}
                name='Usman Sharif'
                position={{ color: 'white', label: 'Senior Software Engineer' }}
              />
            </MKBox>
          </Grid>
          
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                image={Abdullah}
                name='Abdullah Shahbaz'
                position={{ color: 'white', label: 'MERN Stack Developer' }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                image={Asim}
                name='Muhammad Asim'
                position={{ color: 'white', label: 'MERN Stack Developer' }}
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                // image={Rizwan}
                name='Mubashir Anwaar'
                position={{ color: 'white', label: 'Front-End Developer' }}
              />
            </MKBox>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                // image={Rizwan}
                name='Bilal Siddique'
                position={{ color: 'white', label: 'Front-End Developer' }}
              />
            </MKBox>
          </Grid>
          {/* <Grid item xs={12} sm={12} md={6} lg={4}>
            <MKBox>
              <HorizontalTeamCard
                image={Umair}
                name="Umair Shafique"
                position={{ color: "white", label: "Senior Android Engineer" }}
              />
            </MKBox>
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
