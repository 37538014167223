// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import bgImage from "assets/images/countersBackground.jpg";

function Counters() {
  return (
    <MKBox
      component="section"
      py={3}
      style={{ backgroundImage: `url(${bgImage}` }}
    >
      <Container>
        <Grid container>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            xl={4}
            style={{
              padding: "10px",
            }}
          >
            <div
              style={{
                alignItems: "center",
                border: "1px solid white",
                backgroundColor: "#FCFCFE",
                borderRadius: "5px",
                paddingTop: "25px",
              }}
            >
              <Grid
                item
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#E2EDFD",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <TaskAltIcon
                  style={{
                    color: "#086ad8",
                    width: "30px",
                    height: "30px",
                    margin: "10px",
                  }}
                />
              </Grid>
              <DefaultCounterCard
                count={50}
                suffix="+"
                title="Completed Projects"
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            xl={4}
            style={{
              padding: "10px",
            }}
          >
            <div
              style={{
                alignItems: "center",
                border: "1px solid white",
                backgroundColor: "#FCFCFE",
                borderRadius: "5px",
                paddingTop: "25px",
              }}
            >
              <Grid
                item
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#E2EDFD",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <SentimentSatisfiedAltIcon
                  style={{
                    color: "#086ad8",
                    width: "30px",
                    height: "30px",
                    margin: "10px",
                  }}
                />
              </Grid>
              <DefaultCounterCard count={30} suffix="+" title="Happy Clients" />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            xl={4}
            style={{
              padding: "10px",
            }}
          >
            <div
              style={{
                alignItems: "center",
                border: "1px solid white",
                backgroundColor: "#FCFCFE",
                borderRadius: "5px",
                paddingTop: "25px",
              }}
            >
              <Grid
                item
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#E2EDFD",
                  width: "50px",
                  height: "50px",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "auto",
                }}
              >
                <SupportAgentIcon
                  style={{
                    color: "#086ad8",
                    width: "30px",
                    height: "30px",
                    margin: "10px",
                  }}
                />
              </Grid>
              <DefaultCounterCard
                count={10}
                suffix="+"
                title="Multi Services"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
