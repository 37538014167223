import LanguageIcon from "@mui/icons-material/Language";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DevicesIcon from "@mui/icons-material/Devices";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import LaptopIcon from "@mui/icons-material/Laptop";
import GroupsIcon from "@mui/icons-material/Groups";

export default [
  {
    icon: (
      <LanguageIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#79e8e2",
    title: "Web Development",
    description:
      "Hire web designers and developers to develop your websites in a distinct way.",
    //   route: "/services",
  },
  {
    icon: (
      <LaptopIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#fcc774",
    title: "Software Development",
    description:
      "Hire software developers to develop your software for your company.",
    //   route: "/services",
  },
  {
    icon: (
      <DeveloperModeIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#84b7fd",
    title: "App Development",
    description: "Hire app developers to develop your apps for mobile devices.",
    //   route: "/services",
  },
  {
    icon: (
      <GroupsIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#deb0fe",
    title: "IT Consultancy",
    description:
      "Hire professionals to get your challenges solved in Infotmation Technology.",
    //   route: "/services",
  },
  {
    icon: (
      <ShoppingCartIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#fe929f",
    title: "E-Commerce",
    description:
      "Hire experts to build , run and establish your E-commerce business.",
    //   route: "/services",
  },
  {
    icon: (
      <DevicesIcon
        style={{
          color: "#fff",
          width: "30px",
          height: "30px",
          margin: "20px",
        }}
      />
    ),
    backgroundColor: "#2e1342",
    title: "IT Solutions",
    description:
      "Contact us for build in IT solutions provided by our team of professionals.",
    //   route: "/services",
  },
];
