// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/carouselBg.jpg";

function BuiltByDevelopers() {
  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({
          functions: { linearGradient, rgba },
          palette: { gradients },
        }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container sx={{ ml: { xs: 0, lg: 6 } }}>
          <Grid item xs={12} lg={8}>
            <MKTypography variant="h3" color="white" mb={2}>
              How To Generate Creative Ideas For Your IT Business
            </MKTypography>
          </Grid>
          <br />
          <br />
          <Grid item xs={12} lg={11}>
            <MKTypography
              variant="body1"
              style={{ textAlign: "justify" }}
              color="white"
              opacity={0.8}
              mb={1}
            >
              Understand your target audience and their needs is crucial in
              generating creative ideas that resonate with them. Conduct market
              research and analyze customer feedback to identify pain points and
              areas for improvement. Brainstorming sessions can be a great way
              to generate new ideas. Encourage your team to share their ideas,
              and be open to new and unconventional approaches. Collaboration
              with other businesses, professionals, and industry experts can
              help you gain new perspectives and insights. Consider forming
              partnerships or joining industry associations and forums.
              Encourage experimentation and risk-taking within your team. Allow
              your employees to test new ideas and approaches, and be open to
              trying out unconventional solutions. When generating ideas, always
              keep the user experience in mind. Consider how your solutions will
              benefit your users and make their lives easier. Feedback is
              essential in refining and improving your ideas. Encourage feedback
              from your team, customers, and other stakeholders, and use it to
              refine your solutions.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
