import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";

function SocialLinks() {
  return (
    <Container>
      <Grid
        container
        item
        xs={12}
        lg={8}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{ mx: "auto", textAlign: "center" }}
      >
        <MKTypography variant="h6" color="white" mt={8} mb={1}>
          Find us on
        </MKTypography>
        <MKBox display="flex" justifyContent="center" alignItems="center">
          <MKTypography
            component="a"
            variant="body1"
            color="white"
            href="https://www.facebook.com/devstrings"
            target="_blank"
            mr={3}
          >
            <FacebookIcon />
          </MKTypography>
          <MKTypography
            component="a"
            variant="body1"
            color="white"
            href="https://www.twitter.com/StringsDev"
            target="_blank"
            mr={3}
          >
            <TwitterIcon />
          </MKTypography>
          <MKTypography
            component="a"
            variant="body1"
            color="white"
            href="https://www.instagram.com/devstrings"
            target="_blank"
            mr={3}
          >
            <InstagramIcon />
          </MKTypography>
          <MKTypography
            component="a"
            variant="body1"
            color="white"
            href="https://www.linkedin.com/company/devstrings"
            target="_blank"
          >
            <LinkedInIcon />
          </MKTypography>
        </MKBox>
      </Grid>
    </Container>
  );
}

export default SocialLinks;
