import React from "react";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import ContactUsImage from "assets/images/contact.png";

function ContactForm() {
  return (
    <Grid
      container
      spacing={3}
      alignItems="center"
      mt={{ xs: 5, sm: 5, md: 5, lg: 5 }}
      mb={{ xs: 5, sm: 5, md: 5, lg: 8 }}
    >
      <Grid item xs={0} sm={0} md={1} lg={1} xl={2}></Grid>
      <Grid item xs={0} sm={0} md={0} lg={5} xl={4}>
        <MKBox display={{ xs: "none", lg: "flex" }} borderRadius="lg">
          <img
            src={ContactUsImage}
            alt="image"
            style={{ margin: "auto", width: "430px" }}
          />
        </MKBox>
      </Grid>
      <Grid item xs={12} sm={10} md={10} lg={5} xl={4}>
        <MKBox
          bgColor="white"
          borderRadius="xl"
          shadow="lg"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mx={5}
        >
          <MKBox
            variant="gradient"
            bgColor="info"
            coloredShadow="info"
            borderRadius="lg"
            p={2}
            mx={2}
            mt={-3}
          >
            <MKTypography variant="h3" color="white">
              Contact us
            </MKTypography>
          </MKBox>
          <MKBox p={3}>
            <MKTypography variant="body2" color="text" mb={3}>
              For further questions & inquiries, please email us at
              contact@devstrings.com or contact us using our contact form.
            </MKTypography>
            <MKBox
              width="100%"
              component="form"
              method="post"
              autoComplete="off"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <MKInput
                    variant="standard"
                    label="Full Name"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKInput
                    type="email"
                    variant="standard"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="Subject?"
                    placeholder="Your Subject"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MKInput
                    variant="standard"
                    label="How can we help you?"
                    placeholder="Your Message"
                    InputLabelProps={{ shrink: true }}
                    multiline
                    fullWidth
                    rows={6}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                justifyContent="center"
                xs={12}
                mt={5}
                mb={2}
              >
                <MKButton type="submit" variant="gradient" color="info">
                  Send Message
                </MKButton>
              </Grid>
            </MKBox>
          </MKBox>
        </MKBox>
      </Grid>
      <Grid item xs={0} sm={0} md={1} lg={1} xl={2}></Grid>
    </Grid>
  );
}

export default ContactForm;
